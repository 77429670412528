
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
import Api from '@/api/Api'

export default defineComponent({
  name: 'Profile',
  components: {
    NavBar
  },
  data () {
    return {
      loading: true,
      focused: false,
      focusedinterval: undefined as number | undefined,

      id: '',
      email: '',
      username: '',
      wins: 0,
      wkPoints: 0,

      editingusername: false,
      newusername: ''
    }
  },
  mounted () {
    Api.getProfile().then(p => {
      this.id = p._id
      this.email = p.email
      this.username = p.username
      this.wins = p.wins
      this.wkPoints = p.wk_points

      this.loading = false

      this.newusername = this.username
    })

    this.focusedinterval = setInterval(this.updateFocused, 100)
  },
  beforeUnmount () {
    if (this.focusedinterval) {
      clearInterval(this.focusedinterval)
    }
  },
  methods: {
    editusername () {
      if (!/^.{3,32}$/.test(this.newusername)) {
        this.$error.emit({ message: 'The username needs to be between 3 and 32 characters' })
        return
      }
      if (this.newusername === this.username) {
        this.editingusername = false
        return
      }
      Api.setUsername(this.newusername).then(ok => {
        if (ok) {
          this.editingusername = false
          this.username = this.newusername
        }
      })
    },
    updateFocused () {
      this.focused = document.activeElement === document.getElementById('editusername')
    }
  }
})
